<template>
    <v-main class="app-container">
        <v-card>
            <div class="overlay" v-show="overlay">
                <div class="loading-message"><span style="color: white; font-size: 14px;">{{ overlayText }}</span></div>
            </div>
            <v-row no-gutters justify="center" align="center" class="banner-container">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <img @load="onImg1Load" id="banner" src="@/assets/images/logo.jpg" style="width: 100%; height: 100%;">
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>

    
            <v-container class="content-container">
                <v-row v-show="!navigation_visibility" class="progress-nav mb-4">
                    <v-col class="progress-nav-item" cols="10" style="padding: 0px !important;" :class="{active:($route.name=='Company')}">
                        <v-row no-gutters justify="center" align="center">
                                <img @load="onImg2Load" src="@/assets/images/ael-company-icon.png" :style="$route.name=='Company' ? 'width: 80px !important; height: 80px !important;' : 'width: 31.25px !important; height: 31.25px !important;'">
                        </v-row>
                    </v-col>
                </v-row>
              
                <v-row v-show="navigation_visibility" class="progress-nav" style="width: 320px !important;">
                    <v-col class="progress-nav-item" style="padding: 0px !important;" @click="handleCompany" :class="{active:($route.name=='Company')}">
                        <v-row no-gutters justify="center" align="center">
                            <v-col cols="3" v-show="$route.name!='Company'">
                            </v-col>
                            <v-col >
                                <img @load="onImg2Load" src="@/assets/images/ael-company-icon.png" :style="$route.name=='Company' ? 'width: 43.75px !important; height: 43.75px !important;' : 'width: 31.25px !important; height: 31.25px !important;'">
                            </v-col>

                            <!-- accredited condition -->
                            <v-col cols="3" v-show="$route.name!='Company' && $route.name!='Calculator' && accredited_company == true">
                                <span class="dot"></span>
                            </v-col>
                            <v-col style="padding-left: 10px !important;" cols="3" v-show="$route.name=='Calculator' && accredited_company == true">
                                <span class="dot"></span>
                            </v-col>

                            <v-col cols="3" v-show="$route.name!='Company' && $route.name!='CompanyDetails' && accredited_company == false">
                                <span class="dot"></span>
                            </v-col>
                            <v-col style="padding-left: 10px !important;" cols="3" v-show="$route.name=='CompanyDetails' && accredited_company == false">
                                <span class="dot"></span>
                            </v-col>
                            <!-- end -->

                        </v-row>
                        <v-row no-gutters justify="center" align="center">
                            <span class="nav-text">Company</span>
                        </v-row>
                    </v-col>
                    <v-col v-show="accredited_company == true" class="progress-nav-item" style="padding: 0px !important;" @click="handleCalculator" :class="{active:($route.name=='Calculator')}">
                        <v-row no-gutters justify="center" align="center">
                            <v-col style="padding-right: 10px !important;" cols="3" v-show="$route.name=='Company'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='Company' && $route.name!='Calculator'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col >
                                <img @load="onImg2Load" src="@/assets/images/calculating.svg" :style="$route.name=='Calculator' ? 'width: 43.75px !important; height: 43.75px !important;' : 'width: 31.25px !important; height: 31.25px !important;'">
                            </v-col>
                            <v-col style="padding-left: 10px !important;" cols="3" v-show="$route.name=='Profile'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='Calculator' && $route.name!='Profile'">
                                <span class="dot"></span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center" align="center">
                            <span class="nav-text">Calculator</span>
                        </v-row>
                    </v-col>
                    <v-col v-show="accredited_company == false" class="progress-nav-item" style="padding: 0px !important;" @click="handleCompanyDetails" :class="{active:($route.name=='CompanyDetails')}">
                        <v-row no-gutters justify="center" align="center">
                            <v-col style="padding-right: 10px !important;" cols="3" v-show="$route.name=='Company'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='Company' && $route.name!='CompanyDetails'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col >
                                <img @load="onImg2Load" src="@/assets/images/ael-details-icon.png" :style="$route.name=='CompanyDetails' ? 'width: 43.75px !important; height: 43.75px !important;' : 'width: 31.25px !important; height: 31.25px !important;'">
                            </v-col>
                            <v-col style="padding-left: 10px !important;" cols="3" v-show="$route.name=='Profile'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='CompanyDetails' && $route.name!='Profile'">
                                <span class="dot"></span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center" align="center">
                            <span class="nav-text">Details</span>
                        </v-row>
                    </v-col>
                    <v-col class="progress-nav-item" style="padding: 0px !important;" @click="handleProfile" :class="{active:($route.name=='Profile')}">
                        <v-row no-gutters justify="center" align="center">
                            
                            <!-- accredited condition -->
                            
                            <v-col style="padding-right: 10px !important;" cols="3" v-show="$route.name=='Calculator' && accredited_company == true">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='Calculator' && $route.name!='Profile' && accredited_company == true">
                                <span class="dot"></span>
                            </v-col>

                            <v-col style="padding-right: 10px !important;" cols="3" v-show="$route.name=='CompanyDetails' && accredited_company == false">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='CompanyDetails' && $route.name!='Profile' && accredited_company == false">
                                <span class="dot"></span>
                            </v-col>
                            <!-- end -->

                            <v-col >
                                <img @load="onImg3Load" src="@/assets/images/man.svg" :style="$route.name=='Profile' ? 'width: 43.75px !important; height: 43.75px !important;' : 'width: 31.25px !important; height: 31.25px !important;'">
                            </v-col>
                            <v-col style="padding-left: 10px !important;" cols="3" v-show="$route.name=='Address'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='Address' && $route.name!='Profile'">
                                <span class="dot"></span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center" align="center">
                            <span class="nav-text">Profile</span>
                        </v-row>
                    </v-col>
                    <v-col class="progress-nav-item" style="padding: 0px !important;" @click="handleAddress" :class="{active:($route.name=='Address')}">
                        <v-row no-gutters justify="center" align="center">
                            <v-col style="padding-right: 10px !important;" cols="3" v-show="$route.name=='Profile'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='Profile' && $route.name!='Address'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col>
                                <img @load="onImg4Load" src="@/assets/images/house.svg" :style="$route.name=='Address' ? 'width: 43.75px !important; height: 43.75px !important;' : 'width: 31.25px !important; height: 31.25px !important;'">
                            </v-col>
                            <v-col style="padding-left: 10px !important;" cols="3" v-show="$route.name=='Attachment'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='Attachment' && $route.name!='Address'">
                                <span class="dot"></span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center" align="center">
                            <span class="nav-text">Address</span>
                        </v-row>
                    </v-col>
                    <v-col class="progress-nav-item" style="padding: 0px !important;" @click="handleAttachment" :class="{active:($route.name=='Attachment')}">
                        <v-row no-gutters justify="center" align="center">
                            <v-col style="padding-right: 10px !important;" cols="3" v-show="$route.name=='Address'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='Attachment' && $route.name!='Address'">
                                <span class="dot"></span>
                            </v-col>
                            <v-col>
                                <img @load="onImg5Load" src="@/assets/images/attachment.svg" :style="$route.name=='Attachment' ? 'width: 43.75px !important; height: 43.75px !important;' : 'width: 31.25px !important; height: 31.25px !important;'">
                            </v-col>
                            <v-col cols="3" v-show="$route.name!='Attachment'">
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center" align="center">
                            <span class="nav-text">Attachment</span>
                        </v-row>
                    </v-col>
                </v-row>
                
                <!-- content -->
                <app-main />

            </v-container>
        </v-card>
        <vue-splash

            :show="!showMain"
            :logo="logo"
            color="#F5A847"
            :size="150"
            :fixed="true"
        />
    </v-main>
</template>

<script>

import { AppMain } from './components'
import logo from '../assets/images/splash-talktomaria.png'
import { mapGetters } from 'vuex'

export default {
    components: {
        AppMain
    },
    data: function() {
        return{
            spinner_loading: true,

            isLoaded1: false,
            isLoaded2: false,
            isLoaded3: false,
            isLoaded4: false,
            isLoaded5: false,
        }
    },
    computed: {
        ...mapGetters([
            'accredited_company',
            'navigation_visibility',
            'overlay',
            'overlayText',
            'resubmit' // false default value, pag nag fail ang submission magiging true at ididisable ang ibang menus
        ]),
        logo() {
            return logo;
        },
        showMain(){
            return  this.isLoaded1 && 
                    this.isLoaded2 && 
                    this.isLoaded3 && 
                    this.isLoaded4 &&
                    this.isLoaded5
        },
    },
    methods:{
        onImg1Load () {
            setTimeout(() => this.isLoaded1 = true, 2000);
        },
        onImg2Load () {
            this.isLoaded2 = true
        },
        onImg3Load () {
            this.isLoaded3 = true
        },
        onImg4Load () {
            this.isLoaded4 = true
        },
        onImg5Load () {
            this.isLoaded5 = true
        },
        handleCompany: async function() {
            if(this.resubmit == false) {
                let from  = this.$route.fullPath
                let to    = this.$router.resolve({ path: '/' }).route.fullPath
                if(from === to) { 
                // handle any error due the redundant navigation here
                // or handle any other param modification and route afterwards
                return 
                }
                this.$store.dispatch('user/navVisibility', false) // show nav
                this.$router.push({ path: '/company' })
            }     
        },
        handleCompanyDetails: async function() {
            if(this.resubmit == false) {
                let from  = this.$route.fullPath
                let to    = this.$router.resolve({ path: '/' }).route.fullPath
                if(from === to) { 
                // handle any error due the redundant navigation here
                // or handle any other param modification and route afterwards
                return 
                }
                this.$router.push({ path: '/company-details' })
            }     
        },
        handleCalculator: async function() {
            if(this.resubmit == false) {
                let from  = this.$route.fullPath
                let to    = this.$router.resolve({ path: '/calculator/index' }).route.fullPath
                if(from === to) { 
                // handle any error due the redundant navigation here
                // or handle any other param modification and route afterwards
                return 
                }
                this.$router.push({ path: '/calculator/index' })
            }     
        },
        handleProfile: async function(){
            if(this.resubmit == false) {
                let from  = this.$route.fullPath
                let to    = this.$router.resolve({ path: '/profile/index' }).route.fullPath
                if(from === to) { 
                // handle any error due the redundant navigation here
                // or handle any other param modification and route afterwards
                return 
                }
                this.$router.push({ path: '/profile/index' })
            }
        },
        handleAddress: async function(){
            if(this.resubmit == false) {
                let from  = this.$route.fullPath
                let to    = this.$router.resolve({ path: '/address/index' }).route.fullPath
                if(from === to) { 
                // handle any error due the redundant navigation here
                // or handle any other param modification and route afterwards
                return 
                }
                this.$router.push({ path: '/address/index' })
            }
        },
        handleAttachment: async function(){
            if(this.resubmit == false) {
                let from  = this.$route.fullPath
                let to    = this.$router.resolve({ path: '/attachment/index' }).route.fullPath
                if(from === to) { 
                // handle any error due the redundant navigation here
                // or handle any other param modification and route afterwards
                return 
                }
                this.$router.push({ path: '/attachment/index' })
            }
        }
    },

}
</script>