const getters = {
  company: state => state.user.companyInfo,
  companyDetails: state => state.user.companyDetailsInfo,
  calculator: state => state.user.calculatorInfo,
  profile: state => state.user.profileInfo,
  address: state => state.user.addressInfo,
  attachment: state => state.user.attachmentInfo,

  companyFormIsValid: state => state.user.calculatorFormIsValid,
  companyDetailsFormIsValid: state => state.user.calculatorFormIsValid,
  calculatorFormIsValid: state => state.user.calculatorFormIsValid,
  profileFormIsValid: state => state.user.profileFormIsValid,
  addressFormIsValid: state => state.user.addressFormIsValid,
  attachmentFormIsValid: state => state.user.attachmentFormIsValid,

  overlay: state => state.user.overlay,
  overlayText: state => state.user.overlayText,

  resubmit: state => state.user.resubmit,
  submitted: state => state.user.submitted,
  
  fb_fname: state => state.user.fb_fname,
  fb_lname: state => state.user.fb_lname,
  fb_messengerid: state => state.user.fb_messengerid,
  fb_gender: state => state.user.fb_gender,
  fb_profilepic: state => state.user.fb_profilepic,

  navigation_visibility: state => state.user.navigation_visibility,
  accredited_company: state => state.user.accredited_company,
}
export default getters
