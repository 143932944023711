import router from './router'
import store from './store'


router.beforeEach(async(to, from, next) => {

    if(store.getters.resubmit == true){
        await store.dispatch('user/logout')
        if(to.name == 'Calculator'){
            next()
        } else {
            next({name: 'Calculator', replace: true })
        }
    } else {

        if(store.getters.submitted == true){
            await store.dispatch('user/logout')
            if(to.path == '/last-page'){
                next()
            } else {
                next({path: '/last-page', replace: true })
            }
        } else {
            next()
        }
    }

})


