import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    companyInfo: {},
    companyDetailsInfo: {},
    calculatorInfo: {},
    profileInfo: {},
    addressInfo: {},
    attachmentInfo: {},

    // form validation
    companyFormIsValid: false,
    companyDetailsFormIsValid: false,
    calculatorFormIsValid: false,
    profileFormIsValid: false,
    addressFormIsValid: false,
    attachmentFormIsValid: false,

    // overlay
    overlay: false,
    overlayText: '',

    error_files: [],
    
    resubmit: false,
    submitted: false,

    fb_fname: '',
    fb_lname: '',
    fb_messengerid: '',
    fb_gender: '',
    fb_profilepic: '',

    navigation_visibility: false,
    accredited_company: true,

  }
}

const state = getDefaultState()

const mutations = {
  SET_COMPANY_INFO: (state, companyInfo) => {
    state.companyInfo = companyInfo
  },
  SET_COMPANY_DETAILS_INFO: (state, companyDetailsInfo) => {
    state.companyDetailsInfo = companyDetailsInfo
  },
  SET_CALCULATOR_INFO: (state, calculatorInfo) => {
    state.calculatorInfo = calculatorInfo
  },
  SET_PROFILE_INFO: (state, profileInfo) => {
    state.profileInfo = profileInfo
  },
  SET_ADDRESS_INFO: (state, addressInfo) => {
    state.addressInfo = addressInfo
  },
  SET_ATTACHMENT_INFO: (state, attachmentInfo) => {
    state.attachmentInfo = attachmentInfo
  },

  SET_COMPANY_FORM_IS_VALID: (state, companyFormIsValid) => {
    state.companyFormIsValid = companyFormIsValid
  },
  SET_COMPANY_DETAILS_FORM_IS_VALID: (state, companyDetailsFormIsValid) => {
    state.companyDetailsFormIsValid = companyDetailsFormIsValid
  },
  SET_CALCULATOR_FORM_IS_VALID: (state, calculatorFormIsValid) => {
    state.calculatorFormIsValid = calculatorFormIsValid
  },
  SET_PROFILE_FORM_IS_VALID: (state, profileFormIsValid) => {
    state.profileFormIsValid = profileFormIsValid
  },
  SET_ADDRESS_FORM_IS_VALID: (state, addressFormIsValid) => {
    state.addressFormIsValid = addressFormIsValid
  },
  SET_ATTACHMENT_FORM_IS_VALID: (state, attachmentFormIsValid) => {
    state.attachmentFormIsValid = attachmentFormIsValid
  },

  SET_OVERLAY: (state, overlay) => {
    state.overlay = overlay
  },

  SET_OVERLAY_TEXT: (state, overlayText) => {
    state.overlayText = overlayText
  },

  SET_RESUBMIT: (state, resubmit) => {
    state.resubmit = resubmit
  },

  SET_SUBMITTED: (state, submitted) => {
    state.submitted = submitted
  },

  SET_FB_INFO: (state, fbInfo) => {
    state.fb_fname = fbInfo.fb_fname
    state.fb_lname = fbInfo.fb_lname
    state.fb_messengerid = fbInfo.fb_messengerid
    state.fb_gender = fbInfo.fb_gender
    state.fb_profilepic = fbInfo.fb_profilepic
  },

  SET_NAV_VISIBILITY: (state, navigation_visibility) => {
    state.navigation_visibility = navigation_visibility
  },

  SET_ACCREDITED_COMPANY: (state, accredited_company) => {
    state.accredited_company = accredited_company
  },
}

const actions = {
  // all the pages

  // company
  company({ commit }, company) {
      return new Promise((resolve) => {
          commit('SET_COMPANY_INFO', company )
          resolve()
      })
  },

  // company Details
  companyDetails({ commit }, companyDetailsInfo) {
    return new Promise((resolve) => {
        commit('SET_COMPANY_DETAILS_INFO', companyDetailsInfo )
        resolve()
    })
  },

  // calculator
  calculator({ commit }, calculatorInfo) {
      return new Promise((resolve) => {
          commit('SET_CALCULATOR_INFO', calculatorInfo )
          resolve()
      })
  },

  // profile
  profile({ commit }, profileInfo) {
      return new Promise((resolve) => {
          commit('SET_PROFILE_INFO', profileInfo )
          resolve()
      })
  },

  // addressInfo
  address({ commit }, addressInfo) {
      return new Promise((resolve) => {
          commit('SET_ADDRESS_INFO', addressInfo )
          resolve()
      })
  },

  // attachment
  attachment({ commit }, attachmentInfo) {
      return new Promise((resolve) => {
          commit('SET_ATTACHMENT_INFO', attachmentInfo )
          resolve()
      })
  },

  // company form valid
  companyFormIsValid({ commit }, status) {
    return new Promise((resolve) => {
        commit('SET_COMPANY_FORM_IS_VALID', status )
        resolve()
    })
  },

  // company details form valid
  companyDetailsFormIsValid({ commit }, status) {
    return new Promise((resolve) => {
        commit('SET_COMPANY_DETAILS_FORM_IS_VALID', status )
        resolve()
    })
  },

  // calculator form valid
  calculatorFormIsValid({ commit }, status) {
    return new Promise((resolve) => {
        commit('SET_CALCULATOR_FORM_IS_VALID', status )
        resolve()
    })
  },

  // profile form valid
  profileFormIsValid({ commit }, status) {
    return new Promise((resolve) => {
        commit('SET_PROFILE_FORM_IS_VALID', status )
        resolve()
    })
  },

  // address form valid
  addressFormIsValid({ commit }, status) {
    return new Promise((resolve) => {
        commit('SET_ADDRESS_FORM_IS_VALID', status )
        resolve()
    })
  },

  // attachment form valid
  attachmentFormIsValid({ commit }, status) {
    return new Promise((resolve) => {
        commit('SET_ATTACHMENT_FORM_IS_VALID', status )
        resolve()
    })
  },

  // overlay
  overlay({ commit }, overlay) {
    return new Promise((resolve) => {
        commit('SET_OVERLAY', overlay )
        resolve()
    })
  },

  // overlayText
  overlayText({ commit }, overlayText) {
    return new Promise((resolve) => {
        commit('SET_OVERLAY_TEXT', overlayText )
        resolve()
    })
  },

  // resubmit
  resubmit({ commit }, resubmit) {
    return new Promise((resolve) => {
        commit('SET_RESUBMIT', resubmit )
        resolve()
    })
  },

  // submitted
  submitted({ commit }, submitted) {
    return new Promise((resolve) => {
        commit('SET_SUBMITTED', submitted )
        resolve()
    })
  },

  // Set fb info
  fbInfo({ commit }, fbInfo) {
    return new Promise((resolve) => {
        commit('SET_FB_INFO', fbInfo )
        resolve()
    })
  },

   // Set nav visibility
   navVisibility({ commit }, navVisibility) {
    return new Promise((resolve) => {
        commit('SET_NAV_VISIBILITY', navVisibility )
        resolve()
    })
  },

  // Set accredited company
  accreditedCompany({ commit }, accredited_company) {
    return new Promise((resolve) => {
        commit('SET_ACCREDITED_COMPANY', accredited_company )
        resolve()
    })
  },

  // user logout
  logout() {
    return new Promise((resolve) => {
      resetRouter()
      resolve()
    })
  },

}




export default {
  namespaced: true,
  state,
  mutations,
  actions
}

